import React from "react";
import s from './MenuBtn.module.scss';

const MenuBtn = ({active, handleClick, textMn, textCl}) => {
    const classes = active ? (s.btn+ ' ' +s._active) : s.btn;
    const textBtn = active ? textCl : textMn;
    return (
        <button onClick={()=>handleClick(!active)} className={classes}>{textBtn}</button>
    );
};

export default MenuBtn;