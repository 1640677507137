import React from "react";
import Main from "../Main";

const MainPage = ({openVideo, setVisability, visability}) => {
 
    const id = visability ? "blur" : "clear";
    return (
             <main id={id}>
                 <div className="main__wrap">
                   <Main openVideo={openVideo} setVisability={setVisability}/> 
                </div>
            </main>
        ); 
}

export default MainPage;