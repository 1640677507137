import React from "react";
import { useTranslation } from 'react-i18next';

import s from './Menu.module.scss';

const Menu = ({isActive}) => {
    const classes = isActive ? (s.menu+ ' ' +s.active) : s.menu;
    const wrapClasses = isActive ? (s.nav__wraper+ ' ' +s.active) : s.nav__wraper;
    const { t } = useTranslation();


    const prize21 = t("menu_links.prize21.title");
    const prize21Link = t("menu_links.prize21.link");

    // const bif = t("menu_links.bif.title");
    // const bifLink = t("menu_links.bif.link");

    const short = t("menu_links.short.title");
    const shortLink = t("menu_links.short.link");


    return (
        <>  
        <div tabIndex="-1" className={wrapClasses}>    
        <div className={s.inner__wrap}> 
        <nav className={classes}>
        
        <div className={s.menu__wrap}>
            <ul className={s.menu__list}>
                <div className={s.links}>
                <li key={1}><a target="_blank" rel="noreferrer" href={prize21Link}>{prize21}</a></li>
                <li key={3}><a target="_blank" rel="noreferrer" href={shortLink}>{short}</a></li>
                <li key={4}><a target="_blank" rel="noreferrer" href="https://birdinflight.com/ru/bird-in-flight-prize-21/20211208-bif-prize-21-winner.html">WINNER</a></li>
                </div>

                <div className={s.socials}>  
                <li key={5}>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/bifmag/?utm_medium=copy_link">
                        <svg enableBackground="new 0 0 1024 1024" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><circle cx="512" cy="512" fill="#FA3B5A" r="512"/><path d="m512 256c-69.5 0-78.2.3-105.5 1.5-27.3 1.3-45.8 5.6-62.1 11.9-16.8 6.5-31.1 15.3-45.4 29.5s-23 28.5-29.5 45.4c-6.3 16.3-10.6 34.9-11.9 62.1-1.3 27.3-1.5 36-1.5 105.5s.3 78.2 1.5 105.5c1.3 27.2 5.6 45.8 11.9 62.1 6.5 16.8 15.3 31.1 29.5 45.4 14.2 14.2 28.5 23 45.4 29.5 16.3 6.3 34.9 10.6 62.1 11.9 27.3 1.3 36 1.5 105.5 1.5s78.2-.3 105.5-1.5c27.2-1.3 45.8-5.6 62.1-11.9 16.8-6.5 31.1-15.3 45.4-29.5 14.2-14.2 23-28.5 29.5-45.4 6.3-16.3 10.6-34.9 11.9-62.1 1.3-27.3 1.5-36 1.5-105.5s-.3-78.2-1.5-105.5c-1.3-27.2-5.6-45.8-11.9-62.1-6.5-16.8-15.3-31.1-29.5-45.4-14.2-14.2-28.5-23-45.4-29.5-16.3-6.3-34.9-10.6-62.1-11.9-27.3-1.2-36-1.5-105.5-1.5zm0 46.1c68.3 0 76.5.3 103.5 1.5 25 1.2 38.5 5.3 47.5 8.9 12 4.6 20.5 10.2 29.5 19.1 8.9 9 14.5 17.5 19.1 29.5 3.5 9 7.7 22.5 8.8 47.5 1.2 27 1.5 35.1 1.5 103.5s-.3 76.5-1.6 103.5c-1.3 25-5.5 38.5-9 47.5-4.8 12-10.2 20.5-19.2 29.5-8.9 8.9-17.6 14.5-29.4 19.1-9 3.5-22.7 7.7-47.7 8.8-27.2 1.2-35.2 1.5-103.7 1.5s-76.5-.3-103.7-1.6c-25-1.3-38.7-5.5-47.7-9-12.1-4.8-20.5-10.2-29.4-19.2-9-8.9-14.7-17.6-19.2-29.4-3.5-9-7.7-22.7-9-47.7-1-26.9-1.3-35.2-1.3-103.3 0-68.2.3-76.5 1.3-103.7 1.3-25 5.4-38.7 9-47.7 4.5-12.2 10.2-20.5 19.2-29.5 8.9-8.9 17.3-14.7 29.4-19.2 9-3.5 22.4-7.7 47.4-9 27.2-1 35.2-1.3 103.7-1.3zm0 78.4c-72.6 0-131.5 58.9-131.5 131.5s58.9 131.5 131.5 131.5 131.5-58.9 131.5-131.5-58.9-131.5-131.5-131.5zm0 216.8c-47.1 0-85.3-38.2-85.3-85.3s38.2-85.3 85.3-85.3 85.3 38.2 85.3 85.3-38.2 85.3-85.3 85.3zm167.4-221.9c0 17-13.8 30.7-30.7 30.7-17 0-30.7-13.8-30.7-30.7s13.8-30.7 30.7-30.7c16.9-.1 30.7 13.7 30.7 30.7z" fill="#fff"/>
                        </svg>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://t.me/BifMagRu">
                        <svg viewBox="0 0 256 256" version="1.1"  preserveAspectRatio="xMidYMid">
		<g>
				<path d="M128,0 C57.307,0 0,57.307 0,128 L0,128 C0,198.693 57.307,256 128,256 L128,256 C198.693,256 256,198.693 256,128 L256,128 C256,57.307 198.693,0 128,0 L128,0 Z" fill="#FA3B5A"></path>
				<path d="M190.2826,73.6308 L167.4206,188.8978 C167.4206,188.8978 164.2236,196.8918 155.4306,193.0548 L102.6726,152.6068 L83.4886,143.3348 L51.1946,132.4628 C51.1946,132.4628 46.2386,130.7048 45.7586,126.8678 C45.2796,123.0308 51.3546,120.9528 51.3546,120.9528 L179.7306,70.5928 C179.7306,70.5928 190.2826,65.9568 190.2826,73.6308" fill="#FFFFFF"></path>
				<path d="M98.6178,187.6035 C98.6178,187.6035 97.0778,187.4595 95.1588,181.3835 C93.2408,175.3085 83.4888,143.3345 83.4888,143.3345 L161.0258,94.0945 C161.0258,94.0945 165.5028,91.3765 165.3428,94.0945 C165.3428,94.0945 166.1418,94.5735 163.7438,96.8115 C161.3458,99.0505 102.8328,151.6475 102.8328,151.6475" fill="#D2E5F1"></path>
				<path d="M122.9015,168.1154 L102.0335,187.1414 C102.0335,187.1414 100.4025,188.3794 98.6175,187.6034 L102.6135,152.2624" fill="#B5CFE4"></path>
		</g>
                        </svg>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/BifMagRu/ ">
                        <svg enableBackground="new 0 0 1024 1024" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><circle cx="512" cy="512" fill="#FA3B5A" r="512"/><path d="m551.8 768v-233.2h78.6l11.8-91.3h-90.4v-58.1c0-26.4 7.3-44.3 45.1-44.3h47.9v-81.4c-8.3-1.1-36.9-3.6-70.2-3.6-69.5 0-117.1 42.4-117.1 120.3v67.2h-78.3v91.3h78.3v233.1z" fill="#fff"/>
                        </svg>
                    </a>
                </li>
                </div>
            </ul>
        </div>
        </nav>
        </div> 
        </div>
        
        </>
    );
};

export default Menu;