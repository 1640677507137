import React, { Suspense }  from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App/';
import './i18n';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Preloader from './components/Preloader/';
import Error404 from './components/Error/';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5klBo5Zf1i_j_SnCIluYzk_zFSD0d6MM",
  authDomain: "prize21.firebaseapp.com",
  projectId: "prize21",
  storageBucket: "prize21.appspot.com",
  messagingSenderId: "275002613715",
  appId: "1:275002613715:web:66a0a843599a00712b9926"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


ReactDOM.render(
  <React.StrictMode>
    
    {/* <Suspense fallback={<div>Loading...</div>}> */}
    <Suspense fallback={<Preloader/>}>
          <BrowserRouter>      
          <Routes>
            <Route path="/" element={<App lang={"en"}/>}/>
            <Route path="/ua" element={<App lang={"ua"}/>}/>
            <Route path="/ru" element={<App lang={"ru"}/>}/>
            <Route path="*" element={<Error404/>}/>
          </Routes>
        </BrowserRouter>
    </Suspense>
   
  </React.StrictMode>,
  document.getElementById('root')
);
