import React from 'react';
import s from './Header.module.scss';
import MenuBtn from '../MenuBtn';
import { useTranslation } from 'react-i18next';

import prize21 from '../../assets/logo/BIrd_in_flight_prize_21.svg';
import Languages from '../Languages';


const Header = ({isActive, handleClick, textCl, textMn, changeLanguage, visability}) => {
    const { t } = useTranslation();
    const id = visability ? "blur" : "clear";
    const broad = t("broadcast");
    return (
        <>
        <header id={id} className={s.header}>
            <div className={s.header__padding}>
                <div className={s.header__wrap}> 
                    <div className={s.header__description}>
                        <img src={prize21} alt='BIrd in flight prize ‘21'/>

                    </div>
                    <div className={s.header__broadcast}>
                        <h2>{broad}</h2>
                    </div>
                    <div className={s.header__nav}>
                        <Languages changeLanguage={changeLanguage}/>
                        <MenuBtn textCl={textCl} textMn={textMn} active={isActive} handleClick={handleClick}/>
                    </div>                                
                </div>   
            </div>
        </header>
        </>
    );
}


export default Header;