import React from "react";
import s from './Preloader.module.scss';

const Preloader = () => {
    return (
        <div className={s.preloader__wrapper}>
            {            
            <svg className={s.preloader} viewBox="0 0 48 48">
            <title>emblem</title>
            <polygon points="34 0 14 0 0 14 0 34 14 48 34 48 48 34 48 14 34 0"/>
        </svg>}
        </div>
    );
};

export default Preloader;