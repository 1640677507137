import React from "react";
import s from './PopupVideo.module.scss';
// import NewDiafragma from "../NewDiafragma";

const PopupVideo = ({src, setSrc, visability, setVisability}) => {
    // const [visability, setVisability] = useState(false);
    // const classes = visability ? s.video : (s.video+' '+s.hidden);

    if (!visability){
        return (
            <>
            </>
        )
    }
    const closeVideo = () => {
        setVisability(false);        
        setSrc('');
    }
    // modestbranding=1 – скрывает логотип YouTube. 

    return (
        <>
        {/* <div onClick={closeVideo} className={s.video}> */}
        <div className={s.video}>
            <div className={s.video__container}>
                <div onClick={closeVideo} className={s.video__close}>&times;</div>
                <iframe width="100%"
                    src={`${src}?autoplay=1&color=white&controls=2`}
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                </iframe> 
            </div>
        </div>
        {/* <NewDiafragma/> */}
        <div className={s.black__screen}></div>
        </>
    )

}

export default PopupVideo;