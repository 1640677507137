import React from "react";
import s from './Main.module.scss';
import Aside from "../Aside";

const Main = ({openVideo, setVisability}) => {
    return (<>
        <div className={s.wrap}>
            <Aside openVideo={openVideo} setVisability={setVisability}/>
            <div className={s.item}>
                <div className="iframe-container">
                    <div id="player">
                        <iframe width= '100%'   scrolling="no"
                        src="https://www.youtube.com/embed/DN3vPl4rSvU?&controls=2"
                        title="Broadcast of the finalists' works" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                    </iframe>
                    </div>
                </div>
            </div>
        </div>
        </>
    );

};

export default Main;