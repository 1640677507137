import React from "react";

// import multiplex from '../../assets/logo/LOGO_shad.svg';
import deposit from '../../assets/logo/depositephotos_.png';
import gulliver from '../../assets/logo/Logo_Gulliver_simpe_black_new.svg';
// import bif from '../../assets/logo/bif_logo.svg';

import swiss from '../../assets/logo/-511636298414249882logo_black_bold_eng.svg';
import ph from '../../assets/logo/phmuseum-1.png';
import lfsks from '../../assets/logo/LFSKS.logotipas-1024x738.png';


import s from './Footer.module.scss';

const Footer = ({text, visability}) => {
    const id = visability ? "blur" : "clear";
    return (
        <footer id={id}>
            <div className={s.footer__wrap}>
                <span className={s.partners}>{text}</span>
                <div className={s.footer__links}>
                <div><a href='https://ru.depositphotos.com/'><img src={deposit} alt='depositphotos'></img></a></div>
                <div><a href='https://gullivercenter.com/mfk/'><img src={gulliver} alt='gulliver'></img></a></div>
                <div><a href='https://www.eda.admin.ch/countries/ukraine/en/home/representations/embassy-in-kiev.html/'><img src={swiss} alt='swiss'></img></a></div>
                <div><a href='https://phmuseum.com/ /'><img src={ph} alt='phmuseum'></img></a></div>                
                <div><a href='https://photoklaipeda.lt/'><img src={lfsks} alt='photoklaipeda'></img></a></div>
                </div>
            </div>
        </footer>
    );

};

export default Footer;