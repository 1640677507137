import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({

        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        detection: {
            // lookupQuerystring: '/',
            order: ['path', 'queryString', 'cookie'],
            cache: ['cookie']
        },
    });

export default i18n;