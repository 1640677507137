import React, {useEffect, useState} from "react";
import s from './Aside.module.scss';

const Aside = ({openVideo, setVisability}) => {
    const [videoPos, setVideoPos] = useState(250);

    const onVideoClick = (e) => {
        const src = e.target.getAttribute('data-src');
        openVideo(src);
        setVisability(true);
    }

    useEffect(()=>{
        if (document.body.clientWidth < 1025) {
            
            const listWrap = document.querySelector(`.${s.aside__wrap}`);
            const list = document.querySelector(`.${s.aside__list}`);
            // const listItemWidth = document.querySelector(`.${s.aside__list} li`).clientWidth;
            const listWidth = (list.clientWidth / 2 - (listWrap.clientWidth / 2)); //odd
            // const listWidth = (list.clientWidth / 2 - (listWrap.clientWidth / 2) + (listItemWidth/1.95)); //even
            setVideoPos(listWidth);
            listWrap.scrollLeft = videoPos;
        }

    }, [videoPos]);

    return (
        <aside className={s.aside}>
        <div className={s.aside__wrap}>
            <ul className={s.aside__list}>
                {/* <li key={1}>
                    <iframe 
                        src="https://www.youtube.com/embed/yi4xYrtMxj4"
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                    </iframe>
                    <div data-src="https://www.youtube.com/embed/yi4xYrtMxj4" onClick={onVideoClick} className={s.aside__cover}></div>
                </li> */}
                <li key={1} className="iframe-container">
                    <div id="player">
                        <iframe width= '100%'   scrolling="no"
                        src="https://www.youtube.com/embed/PuyZyA7QRIo"  
                        title="Broadcast of the finalists' works" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                    </iframe>
                    </div>
                    <div data-src="https://www.youtube.com/embed/PuyZyA7QRIo" onClick={onVideoClick} className={s.aside__cover}></div>
                </li>
                <li key={2} className="iframe-container">
                    <div id="player">
                        <iframe width= '100%'   scrolling="no"
                        src="https://www.youtube.com/embed/DfplLomFJoY"  
                        title="Broadcast of the finalists' works" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                    </iframe>
                    </div>
                    <div data-src="https://www.youtube.com/embed/DfplLomFJoY" onClick={onVideoClick} className={s.aside__cover}></div>
                </li>

                <li key={3} className="iframe-container">
                    <div id="player">
                        <iframe width= '100%'   scrolling="no"
                        src="https://www.youtube.com/embed/BpexXI32S4Y"  
                        title="Broadcast of the finalists' works" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen>
                    </iframe>
                    </div>
                    <div data-src="https://www.youtube.com/embed/BpexXI32S4Y" onClick={onVideoClick} className={s.aside__cover}></div>
                </li>

                {/* <li key={1}>
                    <object data="https://www.youtube.com/embed/PuyZyA7QRIo">bird in flight</object>
                    <div data-src="https://www.youtube.com/embed/PuyZyA7QRIo" onClick={onVideoClick} className={s.aside__cover}></div>
                </li> */}
                {/* <li key={2}>
                    <object data="https://www.youtube.com/embed/DfplLomFJoY">bird in flight</object>
                    <div data-src="https://www.youtube.com/embed/DfplLomFJoY" onClick={onVideoClick} className={s.aside__cover}></div>
                </li> */}
                {/* <li key={3}>
                    <object data="https://www.youtube.com/embed/BpexXI32S4Y">bird in flight</object>
                    <div data-src="https://www.youtube.com/embed/BpexXI32S4Y" onClick={onVideoClick} className={s.aside__cover}></div>
                </li> */}

            </ul>
        </div>
        </aside>
    );
}

export default Aside;