import React from "react";
import { NavLink } from "react-router-dom";
import s from './Languages.module.scss';

const Languages = ({changeLanguage}) => {

    return (
        <div className={s.languages}>  
            
            <NavLink onClick={()=>changeLanguage("ua")} to="/ua">UA</NavLink>
            <NavLink onClick={()=>changeLanguage("en")} to="/">EN</NavLink>
            <NavLink onClick={()=>changeLanguage("ru")} to="/ru">RU</NavLink>

        </div> 
    );

}

export default Languages;