import React, {useState} from 'react';
import Header from '../Header';
import Menu from '../Menu';
import Footer from '../Footer';
import PopupVideo from '../PopupVideo';
import MainPage from '../MainPage';
import { useTranslation } from 'react-i18next';
import {Helmet} from 'react-helmet';

// import zaglen from '../../assets/img/withtext_zaglushkaen.png';
// import zaglua from '../../assets/img/withtext_zaglushkaua.png';
// import zaglru from '../../assets/img/withtext_zaglushkaru.png';
// import logo from '../../assets/logo/BIrd_in_flight_prize_‘21.svg';
// import Languages from '../Languages';
import ogImageEn from '../../assets/img/og_en.jpg';
import ogImageUa from '../../assets/img/og_ua.jpg';
import ogImageRu from '../../assets/img/og_ru.jpg';

const App = ({lang}) => {
    const [langState, setLangState] = useState(lang);

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
      i18n.changeLanguage(language); 
      setLangState(language);
    }; 

    const [active, setActive] = useState(false);
    const [src, setSrc] = useState('');
    const [visability, setVisability] = useState(false);
    const root = document.querySelector('#root');
    if(active){
      document.body.style.overflow = "hidden";
      root.style.overflowY = "hidden";
    } else {
      document.body.style.overflow = "auto";
      root.style.overflowY = "auto";
    }

    let ogImage = "https://prize21.birdinflight.com" + ogImageUa;
    if (langState === "en"){
        ogImage = "https://prize21.birdinflight.com" + ogImageEn;
    } else if (langState === "ru"){
        ogImage = "https://prize21.birdinflight.com" + ogImageRu;
    }
    // const deadline = "2021-12-1 19:25";
    console.log(langState);

    // if (langState === "en") {
    //   return (
    //         <>
    //         <Helmet>
    //           <html lang={langState}/>
    //           <meta name="description" content={t("broadcast")}/>
    //         </Helmet>
    //         <div className="zagl__wrap">
    //           <div className="zagl_head">
    //             <img className="logo_zagl" src={logo} alt="logo"></img>
    //             <Languages changeLanguage={changeLanguage}/>
    //           </div>
                
    //           <img  className="zagl" src={zaglen} alt="en"></img>
    //           <h3>{t("zaglTextLink")}
    //             <a target="_blank" rel="noreferrer" href="https://forms.gle/sp2QzCY58A5yEHdH8">here</a>
    //           </h3>
    //         </div>
    //         </>
    //   );
    // }
    // if (langState === "ua") {
    //   return (
    //     <>
    //     <Helmet>
    //       <html lang={langState}/>
    //       <meta name="description" content={t("broadcast")}/>
    //     </Helmet>
    //     <div className="zagl__wrap">
    //       <div className="zagl_head">
    //         <img className="logo_zagl" src={logo} alt="logo"></img>
    //         <Languages  changeLanguage={changeLanguage}/>
    //       </div>
    //       <img  className="zagl" src={zaglua} alt="ua"></img>
    //       <h3>{t("zaglTextLink")}
    //         <a target="_blank" rel="noreferrer" href="https://forms.gle/ZBFaG7DGdimVFcje7">тут</a>
    //       </h3>
    //     </div>
    //     </>
    //   );
    // }
    // if (langState === "ru") {
    //   return (
    //     <>
    //     <Helmet>
    //       <html lang={langState}/>
    //       <meta name="description" content={t("broadcast")}/>
    //     </Helmet>
    //     <div className="zagl__wrap">
    //       <div className="zagl_head">
    //         <img className="logo_zagl" src={logo} alt="logo"></img>
    //         <Languages changeLanguage={changeLanguage}/>
    //       </div>
    //       <img  className="zagl" src={zaglru} alt="ru"></img>
    //       <h3>{t("zaglTextLink")}
    //           <a target="_blank" rel="noreferrer" href="https://forms.gle/w6J3fNxsHt9oMjag9">здесь</a>
    //       </h3>
    //     </div>
        
    //     </>
    //   );
    // }

    return (
        <> 
        <Helmet>
          <html lang={langState}/>
          {/* <meta name="description" content={`${t("broadcast")}(LIVE)`}/> */}
          <meta name="description" content={t("broadcast")}/>
          <meta property="og:title"              content="Bird in Flight Prize ‘21" />
          <meta property="og:description"        content={t("broadcast")} />
          <meta property="og:image"              content={ogImage} />
        </Helmet>
        <Header visability={visability} changeLanguage={changeLanguage} textCl={t("menu.close")} textMn={t("menu.menu")} isActive={active} handleClick={setActive}/>
        <Menu isActive={active}/>
        <MainPage visability={visability} openVideo={setSrc} setVisability={setVisability}/>
        <Footer visability={visability} text={t("partners")}/>
        <PopupVideo visability={visability} setVisability={setVisability} src={src} setSrc={setSrc}/>
        </>
    );
};

export default App;